import { isMobile } from "./isMobile";

const focusOnFieldByRef = (vm, ref) => {
  if (!vm.$refs || Object.keys(vm.$refs) < 1) return;

  if (isMobile()) return;

  vm.$nextTick(() => {
    vm.$refs[ref]?.$el?.querySelector("input").focus();
  });
};

export default focusOnFieldByRef;
