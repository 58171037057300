import BugsnagPluginVue from "@bugsnag/plugin-vue";
import { errorLoggerWrapper } from "./errorLoggerWrapper";

const ErrorLogger = errorLoggerWrapper();

const key = process.env.VITE_BUGSNAG_KEY;

export const initErrorLogger = () => {
  if (key) {
    ErrorLogger.start({
      apiKey: key,
      plugins: [new BugsnagPluginVue()],
    });
  }

  return ErrorLogger;
};

export const errorLoggerNotify = (error, ev) => {
  if (key) {
    ErrorLogger.notify(error, ev);
  }
};

export default ErrorLogger;
