<template>
  <form @submit.prevent="submit" @keydown.enter="handleEnterEvent">
    <fieldset class="form-wrapper__input-group">
      <legend class="s-is-hidden">Selecione os campos de Estado, cidade e especialidade:</legend>

      <Select
        ref="uf"
        v-model="state.uf"
        custom-label="Estado"
        select-value="uf"
        input-id="state"
        label="name"
        api-route="/v1/general/lists/states"
        options-icon="icon-location-tick"
        :params="{ per_page: 30, scope: 'merchant-unit' }"
        :errors="v$.uf.$errors"
        :should-fetch-on-search="false"
        @rawValue="handleUfChange($event)"
      />

      <Spacer mobile="16px" desktop="0" />

      <Select
        ref="cities"
        v-model="state.city"
        custom-label="Cidade"
        select-value="name"
        input-id="city"
        label="name"
        api-route="/v1/general/lists/cities"
        options-icon="icon-location-tick"
        :params="{ per_page: 16, uf: state.uf, scope: 'merchant-unit' }"
        :errors="v$.city.$errors"
        :disabled="!state.uf"
        @rawValue="handleCityChange($event)"
      />

      <Spacer mobile="16px" desktop="0" />

      <Select
        ref="specialties"
        v-model="state.specialty"
        custom-label="Especialidade"
        select-value="slug"
        input-id="location-specialty"
        label="name"
        api-route="/v1/general/lists/specialties"
        options-icon="icon-note"
        :default-option="{ name: 'Todas as especialidades', slug: 'all' }"
        :params="{ per_page: 16 }"
        :errors="v$.specialty.$errors"
        @rawValue="handleSpecialtyChange($event)"
      />
    </fieldset>

    <Spacer mobile="16px" desktop="32px" />

    <footer>
      <button
        :class="{ 'm-button-primary': true, '--is-loading': isLoading }"
        type="submit"
        :disabled="isLoading"
      >
        <i class="icon-search-status --icon-default" aria-hidden="true"></i>
        Pesquisar

        <Spinner />
      </button>

      <Spacer mobile="32px" desktop="0" />

      <FormDetail />
    </footer>
  </form>
</template>

<script>
import { computed, reactive } from "vue";

import useValidate from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";

import { searchType } from "../../../types/searchType";

import focusOnFieldByRef from "../../../utils/focus";

import Spacer from "../../Spacer/index.vue";
import Spinner from "../../Spinner/index.vue";
import Select from "../../inputs/Select/index.vue";
import FormDetail from "../../items/FormDetail/index.vue";

export default {
  components: {
    Spacer,
    Select,
    Spinner,
    FormDetail,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
    currentStep: {
      type: String,
      default: "0",
    },
  },
  emits: ["terms"],
  setup() {
    const requiredMessage = "Este campo é obrigatório";

    const state = reactive({ uf: "", city: "", specialty: "" });
    const rules = computed(() => {
      return {
        uf: {
          required: helpers.withMessage(requiredMessage, required),
        },
        city: {
          required: helpers.withMessage(requiredMessage, required),
        },
        specialty: {
          required: helpers.withMessage(requiredMessage, required),
        },
      };
    });

    const v$ = useValidate(rules, state);
    return { state, v$ };
  },
  data() {
    return {
      specialtyFormatted: "",
      searchType: searchType,
      uf_label: "",
      city_label: "",
      isRouted: {
        type: Boolean,
        default: true,
      },
      canSearchOnEnter: false,
    };
  },
  mounted() {
    this.bindQueryIfRouteExists();
  },
  methods: {
    resetCity() {
      this.$nextTick(() => {
        this.state.city = "";
        this.$refs.cities.value = undefined;
        this.$refs.cities.options = [];
        this.$refs.cities.get();
      });
    },
    async handleUfChange({ name = "", uf = "" }) {
      await this.v$.uf.$validate();
      this.resetCity();
      focusOnFieldByRef(this, "cities");

      this.uf_label = name;
      this.city_label = "";

      this.canSearchOnEnter = false;

      this.mergeQueryString({ uf, uf_label: name, city: "", city_label: "" });
    },
    async handleCityChange({ name = "" }) {
      await this.v$.city.$validate();
      focusOnFieldByRef(this, "specialties");

      this.city_label = name;
      this.canSearchOnEnter = false;

      this.mergeQueryString({ city: name, city_label: name });
    },
    async handleSpecialtyChange({ slug = "", name = "" }) {
      await this.v$.specialty.$validate();
      this.updateSpecialtyFormatted(name);
      this.mergeQueryString({ specialty: slug, specialty_label: name });

      setTimeout(() => {
        this.canSearchOnEnter = true;
      }, 250);
    },
    async submit() {
      const isValid = await this.v$.$validate();

      if (isValid) {
        const { state, specialtyFormatted, uf_label, city_label } = this;
        const { uf = "", city = "", specialty = "" } = state || {};

        this.$emit("terms", {
          uf,
          uf_label,
          city,
          city_label,
          specialty,
          specialty_label: specialtyFormatted,
        });
      }
    },
    mergeQueryString(params = {}) {
      if (!this.isRouted) return;

      this.$router?.replace({ query: { ...this.$route?.query, ...params } });
    },
    updateSpecialtyFormatted(name = "") {
      this.specialtyFormatted = name;
    },
    bindQueryParamsAndSearch() {
      const {
        uf = "",
        uf_label = "",
        city = "",
        city_label = "",
        specialty = "",
        specialty_label = "",
      } = this.$route?.query || {};

      if (uf && uf_label) {
        this.state.uf = uf;
        this.$refs.uf.value = { uf, name: uf_label };
      }

      if (city && city_label) {
        this.state.city = city;
        this.$refs.cities.value = { name: city_label };
      }

      if (specialty && specialty_label) {
        this.state.specialty = specialty;
        this.$refs.specialties.value = {
          slug: specialty,
          name: specialty_label,
        };
        this.updateSpecialtyFormatted(specialty_label);
      }

      const hasAllParameters =
        !!uf && !!uf_label && !!city && !!city_label && !!specialty && !!specialty_label;

      this.$nextTick(async () => {
        if (hasAllParameters && this.currentStep === this.searchType.Location) await this.submit();
      });
    },
    handleEnterEvent() {
      const { canSearchOnEnter = false, state } = this;
      const { uf = "", city = "", specialty = "" } = state;

      if (!!uf && !!city && !!specialty && canSearchOnEnter) this.submit();
    },
    bindQueryIfRouteExists() {
      this.isRouted = this.$route;

      if (!this.isRouted) return;

      this.bindQueryParamsAndSearch();
    },
  },
};
</script>
