<template>
  <span
    class="placeholder m-placeholder"
    :style="{ '--height': height, '--width': width, '--radius': radius }"
    aria-hidden="true"
  ></span>
</template>

<script>
export default {
  props: {
    height: {
      type: String,
      default: "",
    },
    width: {
      type: String,
      default: "",
    },
    radius: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.placeholder {
  display: inline-block;

  width: 100%;
  max-width: var(--width);
  height: var(--height);

  border-radius: var(--radius, 50px);
}
</style>
