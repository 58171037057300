<template>
  <article class="card" :class="{ '--has-colored-background': hasColoredBackground }">
    <header>
      <i :class="`${icon.name} ${icon.color} m-icon-round --icon-default`" aria-hidden="true"></i>

      <slot></slot>

      <div class="card__header-end">
        <slot name="header-end"></slot>
      </div>
    </header>

    <footer>
      <slot name="footer"></slot>
    </footer>
  </article>
</template>

<script>
export default {
  name: "CardDefault",
  props: {
    icon: {
      type: Object,
      default: () => ({
        name: "icon-hospital",
        color: "--icon-primary-tint",
      }),
    },
    hasColoredBackground: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import "./styles";
</style>
