<template>
  <div class="form-detail">
    <img
      src="../../../assets/images/icons/icon-brasil.svg"
      alt=""
      width="24"
      height="24"
      loading="lazy"
      aria-hidden="true"
    />

    <p class="m-caption-3 --font-medium --color-dark-tint">
      A Vale Saúde Sempre está presente em todos os estados brasileiros.
    </p>
  </div>
</template>

<style lang="scss">
@use "src/styles/base/breakpoints";
@use "src/styles/modules/typography/caption";

.form-detail {
  display: flex;
  align-items: flex-start;

  @media (min-width: breakpoints.$size-desktop) {
    padding-top: 16px;
  }

  i {
    color: var(--color-danger-tint);
  }

  .m-caption-3 {
    align-self: center;
    margin-left: 8px;

    @media (min-width: breakpoints.$size-desktop) {
      @include caption.m-caption-2;
      margin-left: 12px;
    }
  }

  @media (min-width: breakpoints.$size-desktop) {
    align-items: center;
  }
}
</style>
