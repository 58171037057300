<template>
  <div :class="['m-spinner', color, size]">
    <div class="m-spinner__body">
      <span class="m-spinner__main"></span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: "",
    },

    size: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
.m-spinner {
  &__body {
    box-sizing: content-box;
    height: 18px;
    width: 18px;
    border: 2px solid rgba(var(--color-light-tint-rgb), 0.3);
    border-radius: 50%;
    position: relative;
    margin: 0 auto;
  }

  &__main {
    position: absolute;
    top: -2px;
    left: -2px;
    height: calc(100% + 4px);
    width: calc(100% + 4px);
    border: 2px solid transparent;
    border-top-color: var(--color-light-tint);
    border-radius: 50%;
    animation: spinner 1s linear infinite;
  }

  &.--spinner-primary-color .m-spinner {
    &__body {
      border: 2px solid rgba(var(--color-primary-tint-rgb), 0.3);
    }

    &__main {
      border-top-color: var(--color-primary-tint);
    }
  }

  &.--lg .m-spinner {
    &__body {
      box-sizing: content-box;
      height: 38px;
      width: 38px;
      border-width: 5px;
    }

    &__main {
      top: -5px;
      left: -5px;
      height: calc(100% + 10px);
      width: calc(100% + 10px);
      border-width: 5px;
    }
  }
}
</style>
