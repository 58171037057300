<template>
  <div class="item-icon">
    <i v-if="icon" :class="`${icon.name} ${icon.color} --icon-default`" aria-hidden="true"></i>

    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    icon: {
      type: Object,
      default: () => ({
        name: null,
        color: null,
      }),
    },
  },
};
</script>

<style lang="scss">
.item-icon {
  display: flex;
  align-items: center;

  i {
    margin-right: 8px;
  }

  .m-caption-2 {
    line-height: 133%;
  }
}
</style>
