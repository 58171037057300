<template>
  <button type="button" class="button-icon-only">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "ButtonIconOnly",
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";

.button-icon-only {
  padding: 12px;

  background-color: var(--color-light-tint);
  color: var(--color-primary-tint);

  border: 1px solid #c2d9ff;
  border-radius: 6px;

  transition: all 0.25s ease;

  i {
    font-size: rem.rem(22px);
  }

  &:hover {
    background-color: var(--color-primary-shade);
    color: var(--color-light-tint);
    border-color: var(--color-primary-shade);
  }
}
</style>
