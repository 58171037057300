<template>
  <p class="s-is-hidden">Carregando resultado de busca, aguarde</p>

  <template v-for="placeholder in [1, 2, 3]" :key="placeholder">
    <li class="select__loading-item">
      <span class="select__loading-icon m-placeholder"></span>
      <span class="select__loading-content m-placeholder"></span>
    </li>

    <li class="select__loading-item">
      <span class="select__loading-icon --small m-placeholder"></span>
      <span class="select__loading-content --small m-placeholder"></span>
    </li>
  </template>

  <div class="select__mobile-spinner">
    <Spinner color="--spinner-primary-color" size="--lg" />
  </div>
</template>

<script>
import Spinner from "../../../../Spinner/index.vue";

export default {
  components: {
    Spinner,
  },
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";

.select__loading {
  &-item {
    display: none !important;
    align-items: center;
    padding: 10px 20px;

    @media (min-width: breakpoints.$size-desktop) {
      display: flex !important;
    }
  }

  &-icon {
    flex: 0 0 rem.rem(20px);
    height: rem.rem(20px);
    border-radius: 50%;
    margin-right: 16px;
  }

  &-content {
    width: 100%;
    max-width: 263px;
    height: rem.rem(16px);
    border-radius: 60px;

    &.--small {
      max-width: 158px;
    }
  }
}

.select__mobile-spinner {
  padding: 64px;

  @media (min-width: breakpoints.$size-desktop) {
    display: none;
  }
}
</style>
