<template>
  <section class="form-wrapper__container">
    <div class="form-wrapper__box">
      <fieldset>
        <legend class="m-caption-2 --font-medium --color-dark-tint">Pesquisar por:</legend>

        <Spacer mobile="10px" desktop="8px" />

        <div class="form-wrapper__radio-group">
          <Radio
            v-model="selected"
            :value="searchType.PostalCode"
            name="search-type"
            label="CEP e Especialidade"
          />

          <Spacer mobile="12px" desktop="0" />

          <Radio
            v-model="selected"
            :value="searchType.Location"
            name="search-type"
            label="Estado, Cidade e Especialidade"
          />
        </div>
      </fieldset>

      <Spacer mobile="34px" desktop="32px" />

      <div v-show="selected == searchType.PostalCode">
        <PostalCode :is-loading="isLoading" :current-step="selected" @terms="search($event)" />
      </div>

      <div v-show="selected == searchType.Location">
        <Location :is-loading="isLoading" :current-step="selected" @terms="search($event)" />
      </div>
    </div>
  </section>
</template>

<script>
import { searchType } from "../../../types/searchType";

import Spacer from "../../../components/Spacer/index.vue";
import Location from "../../../components/forms/Location/index.vue";
import PostalCode from "../../../components/forms/PostalCode/index.vue";
import Radio from "../../../components/inputs/Radio/index.vue";

export default {
  components: {
    Spacer,
    Radio,
    PostalCode,
    Location,
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["search"],
  data() {
    return {
      selected: searchType.PostalCode,
      searchType: searchType,
    };
  },
  watch: {
    selected() {
      this.mergeQueryString();
    },
  },
  mounted() {
    const isRouted = this.$route;

    if (!isRouted) return;

    const queryParamType = this.$route?.query.type;

    if (queryParamType) this.selected = queryParamType;
  },
  methods: {
    search(terms) {
      if (this.isLoading) return;
      this.$emit("search", { terms, type: this.selected });
    },
    mergeQueryString() {
      this.$router?.replace({ query: { ...this.$route?.query, type: this.selected } });
    },
  },
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";

.form-wrapper {
  &__box {
    position: relative;

    padding: 24px 24px 32px;
    margin-top: -305px;

    border-radius: 12px;
    box-shadow: 0px 24px 56px -18px rgba(0, 83, 212, 0.1);
    border: 1px solid rgba(var(--color-primary-tint-rgb), 0.12);

    background-color: var(--color-light-tint);

    @media (min-width: breakpoints.$size-desktop) {
      padding: 40px 56px 56px;
      margin-top: -184px;

      border-radius: 8px;
    }

    footer {
      @media (min-width: breakpoints.$size-desktop) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
      }

      .m-button-primary {
        width: 100%;
        font-size: rem.rem(18px);

        @media (min-width: breakpoints.$size-desktop) {
          flex: 0 0 240px;
        }
      }
    }
  }

  &__radio-group {
    @media (min-width: breakpoints.$size-desktop) {
      display: flex;
      margin: 0 -20px;

      > * {
        margin: 0 20px;
      }

      .spacer {
        display: none;
      }
    }
  }

  form:focus {
    outline: none !important;
  }

  &__input-group {
    @extend .form-wrapper__radio-group;
    margin: 0 -16px;

    > * {
      margin: 0 16px;
      flex: 0.7;
    }

    > div:last-of-type {
      flex: 1;
    }
  }
}
</style>
