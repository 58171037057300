import { focusElement } from "./a11y/focus";

const openModalHandler = (modal) => {
  modal.classList.add("--active");
  modal.removeAttribute("hidden");

  document.body.classList.add("s-is-overflow-hidden");

  setTimeout(() => {
    // Workaround to focus on the modal for accessibility
    modal.setAttribute("tabindex", -1);
    modal.focus();
  }, 100);
};

export const openModal = (modalId) => {
  // Opening a modal without an event
  if (modalId) {
    const modals = document.querySelectorAll("[data-modal]");
    const modal = document.querySelector(`[data-modal=${modalId}]`);
    modals.forEach((modalElement) => {
      modalElement.classList.remove("--active");
      modalElement.setAttribute("hidden", true);
    });
    openModalHandler(modal);
    return;
  }

  // Opening a modal via click
  const buttons = document.querySelectorAll("[data-open]");

  buttons.forEach((button) => {
    button.removeEventListener("click", openModalClickHandler);
    button.addEventListener("click", openModalClickHandler);
  });
};

function openModalClickHandler(event) {
  const element = event.currentTarget;
  const modal = document.querySelector(`[data-modal=${element.getAttribute("data-open")}]`);
  openModalHandler(modal);
}

export const closeModal = () => {
  const buttons = document.querySelectorAll("[data-close]");

  buttons.forEach((button) => {
    button.removeEventListener("click", closeModalClickHandler);
    button.addEventListener("click", closeModalClickHandler);
  });
};

function closeModalClickHandler(event) {
  const element = event.currentTarget;
  const id = element.getAttribute("data-close");
  const focusId = element.getAttribute("data-focus");
  const focus = document.querySelector(`[data-focus=${focusId}]`);
  closeModalById(id, focus);
}

export const closeModalById = (id, focus) => {
  if (!id) {
    return;
  }

  const modal = document.querySelector(`[data-modal=${id}]`);
  const lastFocus = document.querySelector(`[data-open=${id}]`);

  modal.classList.remove("--active");
  modal.setAttribute("hidden", true);
  modal.removeAttribute("tabindex");

  // Return focus to the element passed to the function
  if (focus) {
    focusElement(focus);
  } else if (lastFocus) {
    // Return focus to the element that triggered the modal for accessibility
    focusElement(lastFocus);
  }

  document.body.classList.remove("s-is-overflow-hidden");
};

export const initModals = () => {
  openModal();
  closeModal();
  closeModalById();
};
