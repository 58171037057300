<template>
  <section class="partners">
    <div class="partners__wrapper">
      <h2 class="partners__title m-text-2 --font-medium --color-dark-shade">
        <img
          src="../../assets/images/icons/icon-brasil.svg"
          alt=""
          width="40"
          height="40"
          loading="lazy"
          aria-hidden="true"
        />

        <Spacer mobile="12px" desktop="0"></Spacer>

        Mais de 3 mil laboratórios e clínicas renomados em todo o Brasil
      </h2>

      <Spacer mobile="30px" desktop="69px"></Spacer>

      <h3 class="s-is-hidden">Veja abaixo alguns de nossos parceiros:</h3>

      <div class="swiper partners__slider">
        <ul class="swiper-wrapper partners__list">
          <li class="swiper-slide partners__item">
            <h4 class="s-is-hidden">Sabin - Medicina Diagnóstica</h4>

            <img
              src="../../assets/images/partners/sabin.svg"
              alt="'Logo marca do parceiro Sabin - Medicina Diagnóstica"
              width="133"
              height="50"
              loading="lazy"
            />
          </li>

          <li class="swiper-slide partners__item">
            <h4 class="s-is-hidden">A+ - Medicina Diagnóstica</h4>

            <img
              src="../../assets/images/partners/a+.svg"
              alt="'Logo marca do parceiro A+ - Medicina Diagnóstica"
              width="138"
              height="56"
              loading="lazy"
            />
          </li>

          <li class="swiper-slide partners__item">
            <h4 class="s-is-hidden">Bronstein - Medicina Diagnóstica</h4>

            <img
              src="../../assets/images/partners/bronstein.svg"
              alt="'Logo marca do parceiro Bronstein - Medicina Diagnóstica"
              width="133"
              height="50"
              loading="lazy"
            />
          </li>

          <li class="swiper-slide partners__item">
            <h4 class="s-is-hidden">Hermes Pardini - Medicina, saúde e bem-estar</h4>

            <img
              src="../../assets/images/partners/hermes-pardini.svg"
              alt="'Logo marca do parceiro Hermes Pardini - Medicina, saúde e bem-estar"
              width="133"
              height="50"
              loading="lazy"
            />
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script setup>
import Swiper from "swiper";
import { onMounted } from "vue";

import Spacer from "../../components/Spacer/index.vue";

onMounted(() => {
  new Swiper(".partners__slider", {
    spaceBetween: 0,
    slidesPerView: "auto",
    slidesOffsetBefore: 4,
    slidesOffsetAfter: 14,
    centerInsufficientSlides: true,
    breakpoints: {
      1256: {
        spaceBetween: 64,
        slidesOffsetBefore: 0,
        slidesOffsetAfter: 0,
      },
    },
  });
});
</script>

<style lang="scss">
@use "src/styles/base/breakpoints";

.partners {
  border-bottom: solid 10px var(--color-primary-tint);

  &__wrapper {
    width: 100%;
    max-width: breakpoints.$size-desktop;
    margin: 0 auto;
    padding: 48px 0 65px;
    text-align: center;

    @media (min-width: breakpoints.$size-desktop) {
      padding: 103px 0 136px;
    }
  }

  &__title {
    padding: 0 20px;
    display: inline-block;
    text-align: center;

    @media (min-width: breakpoints.$size-desktop) {
      display: inline-flex;
      align-items: center;

      .m-spacer {
        display: none;
      }
    }

    img {
      display: inline-block;

      @media (min-width: breakpoints.$size-desktop) {
        margin-right: 24px;
      }
    }
  }

  &__slider {
    margin: 0 auto !important;
  }

  &__list {
    height: 64px !important;
  }

  &__item {
    width: auto !important;
    display: flex !important;
    align-items: center !important;
    height: 100% !important;
    margin-right: -10px;

    @media (min-width: breakpoints.$size-desktop) {
      margin: 0;
    }

    img {
      width: auto;
      height: auto;
      transform: scale(0.75);
      filter: grayscale(1);
      opacity: 0.8;
      transition:
        filter 0.25s ease,
        opacity 0.25s ease;

      &:hover {
        filter: none;
        opacity: 1;
      }

      @media (min-width: breakpoints.$size-desktop) {
        transform: none;
      }
    }
  }
}
</style>
