<template>
  <button
    type="button"
    class="m-item-link --font-medium"
    :data-open="dataOpen"
    @click="$emit('click')"
  >
    <p class="m-link">
      <slot></slot>
    </p>

    <div end>
      <i class="icon-arrow-right --font-bold" aria-hidden="true"></i>
    </div>
  </button>
</template>

<script>
export default {
  props: {
    dataOpen: {
      type: String,
      default: "",
    },
  },
  emits: ["click"],
};
</script>

<style lang="scss">
.m-item-link {
  margin: 0 0 6px -12px;
}
</style>
