<template>
  <article class="card card-placeholder" aria-hidden="true">
    <header>
      <PlaceholderItem width="48px" height="48px" radius="50%" />

      <PlaceholderItem class="card__title" width="263px" height="24px" radius="50px" />

      <div class="card__header-end">
        <PlaceholderItem width="155px" height="24px" radius="50px" />
      </div>
    </header>

    <footer class="card-placeholder__footer-mobile">
      <PlaceholderItem width="122px" height="16px" radius="50px" />

      <PlaceholderItem width="263px" height="16px" radius="50px" />

      <PlaceholderItem width="263px" height="16px" radius="50px" />

      <PlaceholderItem class="card-placeholder__bottom" width="125px" height="24px" radius="50px" />
    </footer>

    <footer class="card-placeholder__footer-desktop">
      <div>
        <PlaceholderItem width="122px" height="16px" radius="50px" />

        <PlaceholderItem width="263px" height="16px" radius="50px" />
      </div>

      <div>
        <PlaceholderItem width="122px" height="16px" radius="50px" />

        <PlaceholderItem width="263px" height="16px" radius="50px" />
      </div>

      <PlaceholderItem class="card-placeholder__bottom" width="125px" height="24px" radius="50px" />
    </footer>
  </article>
</template>

<script>
import PlaceholderItem from "../../../PlaceholderItem/index.vue";

export default {
  name: "CardPlaceholder",
  components: {
    PlaceholderItem,
  },
};
</script>

<style lang="scss">
@use "src/styles/base/breakpoints";

.card-placeholder {
  .card__header-end {
    margin-left: auto;
  }

  &__footer-mobile {
    @media (min-width: breakpoints.$size-desktop) {
      display: none !important;
    }
  }

  &__footer-desktop {
    display: none !important;

    @media (min-width: breakpoints.$size-desktop) {
      display: flex !important;
      align-items: flex-end;
      margin: 0 -32px !important;
    }

    > *:not(:last-child) {
      @media (min-width: breakpoints.$size-desktop) {
        margin: 0 32px !important;
      }
    }

    div {
      flex: 0 0 263px;

      .placeholder:first-child {
        margin-bottom: 10px;
      }
    }
  }

  &__bottom {
    margin-top: 58px !important;

    @media (min-width: breakpoints.$size-desktop) {
      margin-top: 0;
      margin-left: auto !important;
    }
  }
}
</style>
