<template>
  <article class="card-error">
    <header>
      <i class="icon-close-circle --color-danger" aria-hidden="true"></i>

      <slot></slot>
    </header>

    <slot name="content"></slot>
  </article>
</template>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";

.card-error {
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  min-height: 240px;

  background-color: var(--color-light);

  padding: 40px 32px;

  border-radius: 6px;

  > *:not(:last-child) {
    margin-bottom: 8px;

    @media (min-width: breakpoints.$size-desktop) {
      margin-bottom: 16px;
    }
  }

  @media (min-width: breakpoints.$size-desktop) {
    min-height: 216px;
  }

  header {
    display: inline-flex;
    align-items: center;
    flex-direction: column;

    text-align: center;

    @media (min-width: breakpoints.$size-desktop) {
      flex-direction: row;
    }

    i {
      font-size: rem.rem(32px);
      margin-bottom: 8px;

      @media (min-width: breakpoints.$size-desktop) {
        margin-bottom: 0;
        margin-right: 16px;
      }
    }
  }

  p {
    text-align: center;
  }
}
</style>
