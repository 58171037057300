<template>
  <div class="input" :class="{ '--has-value': value, '--has-error': errors.length }">
    <div class="input__controller">
      <label class="m-text-3 --font-medium --color-dark-shade" :for="id">{{ label }}</label>

      <input
        :id="id"
        v-model="value"
        v-maska="mask"
        class="m-text-3 --font-medium --color-dark-tint"
        :name="id"
        :inputmode="inputMode"
        @change="$emit('update:modelValue', isRaw ? rawValue : value)"
        @maska="rawValue = $event.target.dataset.maskRawValue"
      />
    </div>

    <p v-if="errors.length" role="alert" class="input__error-message --font-has-limit --2-lines">
      {{ errors[0].$message }}
    </p>
  </div>
</template>

<script>
export default {
  name: "InputText",
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  props: {
    label: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: "",
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    mask: {
      type: String,
      default: "",
    },
    isRaw: {
      type: Boolean,
      default: false,
    },
    inputMode: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: "",
      rawValue: "",
    };
  },
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";
@use "src/styles/modules/input";

.input {
  &__controller {
    position: relative;
    min-height: 64px;
    border: 1px solid var(--color-medium-tint);
    transition: border-color 0.25s ease;
    border-radius: 6px;
    overflow: hidden;
  }

  label {
    position: absolute;
    transition: top 0.15s ease-out;
    z-index: 2;
    pointer-events: none;
    top: 50%;
    transform: translateY(-50%);
    left: 18px;
  }

  input {
    padding: 28px 18px 10px;
    width: 100%;
    border: none;
    outline: none !important;
  }

  &:focus-within {
    @extend .--has-value;

    .input__controller {
      border-color: rgba(var(--color-primary-tint-rgb), 0.5);
    }
  }

  &.--has-value {
    label {
      position: absolute;
      top: 9px;
      transform: none;
      font-size: rem.rem(14px);
    }
  }

  &.--has-error &__controller {
    border-color: var(--color-danger) !important;
  }

  &__error-message {
    @include input.m-input-error-message;

    text-align: left;

    @media (min-width: breakpoints.$size-desktop) {
      margin-bottom: rem.rem(-20px);
    }
  }
}
</style>
