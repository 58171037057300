import { h } from "vue";

export const OpenIndicator = () => {
  return h(
    "button",
    {
      class: "select__arrow",
      type: "button",
      "aria-label": "Expandir/fechar seleção de especialidades",
    },
    [h("i", { class: "icon-arrow-down", "aria-hidden": true })],
  );
};

export const Deselect = (onClick) => {
  return h(
    "button",
    {
      class: "select__deselect",
      type: "button",
      "aria-label": "Limpar opção selecionada",
      onClick,
    },
    [
      h("i", {
        class: "icon-close-circle-filled",
        "aria-hidden": true,
      }),
    ],
  );
};
