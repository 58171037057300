<template>
  <div>
    <div
      class="select"
      :class="{
        '--active': isOpen,
        '--has-value': value,
        '--has-error': errors.length || internalError,
      }"
    >
      <label class="m-text-3 --font-medium --color-dark" :for="inputId">
        {{ customLabel }}
      </label>

      <CloseButton label="Fechar" />

      <vSelect
        ref="select"
        v-model="value"
        :input-id="inputId"
        :class="{ '--is-loading': isLoading }"
        :options="options"
        :components="{ OpenIndicator, Deselect }"
        :label="label"
        :value="selectValue"
        :filter-by="filterBy"
        :disabled="disabled"
        :placeholder="customLabel"
        @open="openSelect"
        @close="closeSelect"
        @search="fetch"
        @option:selected="handleModelChange"
        @search:blur="focusOnParentForm"
      >
        <!-- Elemento customizado para opção na lista -->
        <template #option="{ name }">
          <i :class="optionsIcon" aria-hidden="true"></i>
          <p>{{ name }}</p>
        </template>

        <!-- Elemento customizado para opção selecionada -->
        <template #selected-option="{ name }">
          <p>{{ name }}</p>
        </template>

        <!-- Elemento customizado para lista vazia -->
        <template #no-options>
          <template v-if="!isLoading">
            <i :class="optionsIcon" aria-hidden="true"></i>
            <p>Nenhum resultado encontrado</p>
          </template>
        </template>

        <!-- Elemento customizado para mostrar o "loading" -->
        <template #list-footer>
          <div v-show="hasNextPage || isLoading" ref="load">
            <Placeholder />
          </div>
        </template>
      </vSelect>
    </div>

    <p
      v-if="errors.length || internalError"
      role="alert"
      class="select__error-message --font-has-limit --2-lines"
    >
      {{ formatError || internalError }}
    </p>
  </div>
</template>

<script>
import vSelect from "vue-select";

import { debounce } from "lodash";

import { errorLoggerNotify } from "../../../services/errorLogger";

import api from "../../../services/api";

import { focusElement } from "../../../utils/a11y/focus";
import { isMobile } from "../../../utils/isMobile";
import { slugify } from "../../../utils/slugify";

import CloseButton from "../../buttons/CloseButton/index.vue";
import { Deselect, OpenIndicator } from "./components/Controls/index";
import Placeholder from "./components/Placeholder/index.vue";

export default {
  components: {
    vSelect,
    Placeholder,
    CloseButton,
  },
  model: {
    prop: "modelValue",
    event: "update:modelValue",
  },
  props: {
    //Label flutuante
    customLabel: {
      type: String,
      default: "",
    },
    //Label para as opções
    label: {
      type: String,
      default: "",
    },
    inputId: {
      type: String,
      default: "",
    },
    // Key que define o valor da opção no objeto retornado pela api (Ex.: 'uf')
    selectValue: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    optionsIcon: {
      type: String,
      default: "",
    },
    apiRoute: {
      type: String,
      default: "",
    },
    // Prop para adicionar uma opção no início da lista retornada pela api (Ex.:"Todas as especialidades")
    defaultOption: {
      type: Object || undefined,
      default: undefined,
    },
    params: {
      type: Object,
      default() {
        return {
          per_page: 50,
        };
      },
    },
    errors: {
      type: Array,
      default() {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    shouldFetchOnSearch: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["update:modelValue", "rawValue"],
  data() {
    return {
      value: "",
      isOpen: false,
      settings: {},
      options: [],
      term: "",
      internalError: "",
      isLoading: true,
      isOpening: false,
      pagination: {},
      page: 1,
      observer: null,
      OpenIndicator,
      Deselect: Deselect(this.clearSearchInput),
    };
  },
  computed: {
    hasNextPage() {
      return !!this.pagination.links?.next;
    },
    formatError() {
      if (this.errors.length > 0) {
        return this.errors[0].$message;
      }

      return false;
    },
  },
  mounted() {
    this.get();
    this.observer = new IntersectionObserver(this.infiniteScroll);

    const clearButton = document.querySelector(".vs__clear");
    clearButton.addEventListener("click", (e) => {
      e.stopPropagation();
    });
  },
  methods: {
    async delayFocus() {
      document.activeElement.blur();

      await new Promise((res) =>
        setTimeout(() => {
          const input = document.getElementById(this.inputId);
          input.focus();

          setTimeout(() => {
            res();
            this.isOpening = false;
          }, 200);
        }, 350),
      );
    },
    async openSelect(isDeselect) {
      if (this.isOpening) return;
      this.isOpening = true;

      this.isOpen = true;

      this.toggleBodyOverflow(true);

      if (isMobile()) {
        await this.delayFocus();
      } else {
        const input = document.getElementById(this.inputId);
        input.focus();
        this.isOpening = false;
      }

      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    async closeSelect() {
      if (this.isOpening) return;
      this.isOpen = false;

      this.toggleBodyOverflow(false);

      this.observer.disconnect();
    },
    // Overflow hidden no body, quando mobile
    toggleBodyOverflow(hidden) {
      const body = document.body.classList;

      if (isMobile() && hidden) {
        body.add("s-is-overflow-hidden");
        return;
      }

      body.remove("s-is-overflow-hidden");
    },
    // Filtro customizado para busca (ignora acentos e maiúsculas/minúsculas)
    filterBy(option, label, search) {
      const cleanLabel = slugify(label);
      const cleanSearch = slugify(search);
      return (cleanLabel || "").indexOf(cleanSearch) > -1;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent?.scrollTop;
        await this.loadMore();
        await this.$nextTick();

        if (ul) {
          ul.scrollTop = scrollTop;
        }
      }
    },
    clearSearchInput() {
      this.term = "";
      this.$refs.select.search = "";
      this.value = "";
      this.handleModelChange();
      this.get();
    },
    fetch(search) {
      if (!this.shouldFetchOnSearch) return;
      this.term = search;
      this.page = 1;
      this.options = [];
      // Ativa loading antes do debounce
      this.isLoading = true;
      this.search(this);
    },
    search: debounce((vm) => {
      vm.get();
    }, 450),
    async get() {
      if (!this.apiRoute) throw new Error("API route is required");

      this.isLoading = true;

      let params = { ...this.params, q: this.term, page: this.page };

      try {
        const res = await api.get(this.apiRoute, {
          params,
        });
        this.options = this.page === 1 ? res.data : this.options.concat(res.data);
        this.pagination = res.pagination;
      } catch (e) {
        const message = `Houve um erro ao buscar as informações de ${this.customLabel}, tente novamente`;
        this.internalError = message;

        errorLoggerNotify(new Error("Erro ao buscar dados no select"), (ev) => {
          ev.severity = "error";
          ev.addMetadata("error", e);
          ev.addMetadata("apiRoute", { apiRoute: this.apiRoute });
          ev.addMetadata("params", params);
          ev.addMetadata("label", { label: this.customLabel });
        });
      } finally {
        // Binda no primeiro resultado da lista, um item padrão que não vem da API, exemplo "Todas as especialidades"
        if (this.defaultOption && this.page === 1) this.options.unshift(this.defaultOption);

        this.isLoading = false;
      }
    },
    async loadMore() {
      if (!this.isLoading && this.hasNextPage) {
        this.page++;
        return this.get();
      }
    },
    handleModelChange() {
      this.$emit("update:modelValue", this.value[this.selectValue]);
      this.$emit("rawValue", this.value);
    },
    focusOnParentForm() {
      focusElement(this.$refs.select.$el.closest("form"));
    },
  },
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";
@use "src/styles/modules/typography/caption";
@use "src/styles/modules/input";

@import "src/styles/modules/typography/has-limit";

// Estilos separados para alguns componentes filhos:
@import "styles/reset";
@import "styles/dropdown";
@import "styles/mobile";

.select {
  width: 100%;
  position: relative;

  label {
    transition: top 0.15s ease-out;
    z-index: 2;
    pointer-events: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 20px;
  }

  &__error-message {
    @include input.m-input-error-message;
    text-align: left;

    @media (min-width: breakpoints.$size-desktop) {
      margin-bottom: rem.rem(-20px);
    }
  }

  .close-button {
    display: none;
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

.--has-value {
  label {
    position: absolute;
    top: 9px;
    transform: none;
    font-size: rem.rem(14px);
  }
}

.--has-error .vs__dropdown-toggle {
  border-color: var(--color-danger) !important;
}

.--active {
  @extend .--has-value;
}

.v-select.--is-loading .vs__no-options {
  display: none !important;
}

.vs__dropdown-toggle {
  // Estilos para os actions do select
  @import "styles/actions";
  --vs-actions-padding: 0 15px;

  // Estilos para a valor selecionado
  @import "styles/value";

  min-height: 64px;
  padding: 0 !important;
  transition: border-color 0.25s ease;

  .vs__clear {
    display: none;
  }
}

.vs--open .vs__dropdown-toggle {
  @extend .--active;

  border-bottom-left-radius: 6px !important;
  border-bottom-right-radius: 6px !important;
  border-color: rgba(var(--color-primary-tint-rgb), 0.5) !important;

  @media (min-width: breakpoints.$size-desktop) {
    min-height: 64px;
  }

  .select__arrow {
    display: none !important;
  }

  .vs__clear {
    display: block !important;
  }

  .vs__search {
    position: static;
  }
}
</style>
