<template>
  <a
    id="resultados-de-busca"
    href="#resultados-de-busca"
    class="search-results__anchor m-anchor s-is-hidden"
    aria-hidden="true"
  >
    Início da seção com os resultados da busca
  </a>

  <section class="search-results">
    <Spacer mobile="56px" desktop="70px" />

    <template v-if="shouldDisplayOnlineCard">
      <h2 class="search-results__subtitle m-text-3 --color-dark-tint --font-regular">
        <i class="icon-mobile --icon-default" aria-hidden="true"></i>
        Atendimento online:
      </h2>

      <Spacer mobile="24px" desktop="40px" />

      <ul class="search-results__list">
        <slot name="online-results"></slot>
      </ul>

      <Spacer mobile="56px" desktop="72px" />
    </template>

    <h2 class="search-results__subtitle m-text-3 --color-dark-tint --font-regular">
      <i class="icon-user --icon-default" aria-hidden="true"></i>
      Atendimento presencial:
    </h2>

    <Spacer mobile="24px" desktop="40px" />

    <ul class="search-results__list">
      <slot></slot>
    </ul>

    <footer class="search-results__footer">
      <slot name="footer"></slot>
    </footer>
  </section>
</template>

<script>
import Spacer from "../../../components/Spacer/index.vue";

export default {
  components: {
    Spacer,
  },
  computed: {
    shouldDisplayOnlineCard() {
      return this.$parent.shouldDisplayOnlineCard;
    },
  },
};
</script>

<style lang="scss">
.search-results {
  &__anchor {
    transform: translateY(-64px);
  }

  &__subtitle {
    display: flex;
    align-items: center;

    i {
      margin-right: 12px;
    }
  }

  &__list li:not(:last-child) {
    margin-bottom: 40px;
  }

  &__footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .m-button-primary {
      height: max-content;
      width: max-content;
    }

    &-load-more {
      position: absolute;
      bottom: -26px;
    }
  }
}
</style>
