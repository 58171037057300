<template>
  <button
    type="button"
    class="close-button"
    :aria-label="ariaLabel"
    :data-close="dataClose"
    :data-focus="dataFocus"
  >
    {{ label }}

    <i class="icon-close" aria-hidden="true"></i>
  </button>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    ariaLabel: {
      type: String,
      default: "",
    },
    dataClose: {
      type: String,
      default: "",
    },
    dataFocus: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@use "src/styles/base/mixins/rem";
@use "src/styles/base/breakpoints";
@use "src/styles/modules/typography/caption";

.close-button {
  @include caption.m-caption-2;
  display: flex;
  align-items: center;

  max-width: max-content;

  color: var(--color-primary-tint);
  font-weight: 500;

  i {
    display: block;
    font-size: rem.rem(24px);
    margin-left: 4px;
  }
}
</style>
