import axios from "axios";
import { errorLoggerNotify } from "./errorLogger";

const baseURL = process.env.VITE_BASE_API_URL;

const headers = {
  "vss-origin": "vss",
};

const handleSuccess = (response) => response?.data;
const handleError = (error) => {
  errorLoggerNotify(error);
  return Promise.reject(error?.response);
};

const api = axios.create({
  baseURL,
  headers,
});

const internalApi = axios.create({
  baseURL: "/api",
  headers,
});

api.interceptors.response.use(handleSuccess, handleError);
internalApi.interceptors.response.use(handleSuccess, handleError);

export { internalApi };
export default api;
